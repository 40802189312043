import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { DateUtil } from '@utils/classes/DateUtil';
import { ScaRoutingResuMaritimo } from '~models/carga/dto/resumen/ScaRoutingResuMaritimo';
import { ScaRoutingConcepto, ScaRoutingDto, ScaValidate } from '~models/carga/interface';
import { TbPais } from '~models/maestros/interface';
import { DateFormat } from '~models/Util';

export interface ScaRoutingConfig {
	idViaTransporte: number;
	codViaTransporte: string;
	codTipoManifiesto: string;
}
export interface ScaRoutingTotales {
	bultos?: number | null;
	volumen?: number | null;
	pesoNeto?: number | null;
	pesoBruto?: number | null;
	totalFlete?: number | null;
	contenedores?: string | null;
	nroContenedores?: string | null;
}

export interface ScaRoutingCrudState {
	scaRouting: ScaRoutingDto | null;
	scaRoutingUpd: ScaRoutingDto | null | undefined;
	scaRoutingConfig: ScaRoutingConfig | null;
	scaRoutingFleteCargo: ScaRoutingDto | null;
	scaRoutingConcepto: ScaRoutingConcepto[] | null;
	setScaRefreshListGrupos: boolean;
	scaValidate: ScaValidate | null;
	scaRoutingTotales: ScaRoutingTotales | null;
	tbPais: TbPais | null;
	tbPaisDestino: TbPais | null;
	scaRoutingResuMaritimo: ScaRoutingResuMaritimo | null;
}
export const initialState: ScaRoutingCrudState = {
	scaRouting: null,
	scaRoutingUpd: null,
	scaRoutingConfig: null,
	scaRoutingFleteCargo: null,
	scaRoutingConcepto: null,
	setScaRefreshListGrupos: true,
	scaValidate: null,
	scaRoutingTotales: {
		bultos: 0,
		volumen: 0,
		pesoNeto: 0,
		pesoBruto: 0,
		totalFlete: 0,
		contenedores: '',
		nroContenedores: '',
	},
	tbPais: null,
	tbPaisDestino: null,
	scaRoutingResuMaritimo: null,
};

@Injectable()
export class RoutingOrderStore extends ComponentStore<ScaRoutingCrudState> {
	private _dateUtil = new DateUtil();

	constructor() {
		super(initialState);
	}
	//#region OBSERVABLES
	readonly getScaRouting$ = this.select((state) => state.scaRouting);
	readonly getScaRoutingUpd$ = this.select((state) => state.scaRoutingUpd);

	readonly getScaRoutingConfig$ = this.select((state) => state.scaRoutingConfig);
	readonly getScaRoutingFleteCargo$ = this.select((state) => state.scaRoutingFleteCargo);
	readonly getScaRoutingConcepto$ = this.select((state) => state.scaRoutingConcepto);
	readonly getScaRoutingTotales$ = this.select((state) => state.scaRoutingTotales);

	readonly getScaValidate$ = this.select((state) => state.scaValidate);
	readonly getRefreshListGrupos$ = this.select((state) => state.setScaRefreshListGrupos);

	readonly getTbPais$ = this.select((state) => state.tbPais);
	readonly getTbPaisDestino$ = this.select((state) => state.tbPaisDestino);

	readonly getScaRoutingResuMaritimo$ = this.select((state) => state.scaRoutingResuMaritimo);
	//#endregion

	//#region SETTERS
	readonly setScaRouting = this.updater((state, scaRouting: ScaRoutingDto) => ({ ...state, scaRouting }));
	readonly setScaRoutingUpd = this.updater((state, newResource: Partial<ScaRoutingDto>) => ({
		...state,
		scaRoutingUpd: {
			...state.scaRoutingUpd,
			...newResource,
			scaOrdenCarga: {
				...state.scaRoutingUpd?.scaOrdenCarga,
				...newResource.scaOrdenCarga,
			},
		},
	}));

	readonly setScaRoutingConfig = this.updater((state, scaRoutingConfig: ScaRoutingConfig) => ({ ...state, scaRoutingConfig }));
	readonly setScaRoutingFleteCargo = this.updater((state, scaRoutingFleteCargo: ScaRoutingDto) => ({ ...state, scaRoutingFleteCargo }));
	readonly setScaRoutingConcepto = this.updater((state, scaRoutingConcepto: ScaRoutingConcepto[]) => ({ ...state, scaRoutingConcepto }));
	readonly setScaRoutingTotales = this.updater((state, scaRoutingTotales: ScaRoutingTotales) => ({
		...state,
		scaRoutingTotales,
	}));

	readonly setScaValidate = this.updater((state, validate: ScaValidate) => ({ ...state, validate }));
	readonly setScaRefreshListGrupos = this.updater((state, setScaRefreshListGrupos: boolean) => ({ ...state, setScaRefreshListGrupos }));

	readonly setTbPais = this.updater((state, tbPais: TbPais) => ({ ...state, tbPais }));
	readonly setTbPaisDestino = this.updater((state, tbPaisDestino: TbPais) => ({ ...state, tbPaisDestino }));

	readonly setScaRoutingResuMaritimo = this.updater((state, scaRoutingResuMaritimo: ScaRoutingResuMaritimo) => ({ ...state, scaRoutingResuMaritimo }));
	//#endregion

	//#region GETTERS
	getScaRouting(): ScaRoutingDto | null {
		return this.get().scaRouting;
	}
	getScaRoutingConfig(): ScaRoutingConfig | null {
		return this.get().scaRoutingConfig;
	}
	getScaRoutingResumenMaritimo(): ScaRoutingResuMaritimo | null {
		const ro = this.get().scaRoutingUpd;
		const adic = ro?.scaOrdenCarga?.encargadoDireccionamiento == 'A' ? 'POR AGENCIA' : 'POR CLIENTE';
		const date = this._dateUtil.formatDate(ro?.scaOrdenCarga?.fchDireccionamiento ?? null, DateFormat.DATE);

		console.log('getScaRoutingResumenMaritimo', ro);

		this.setScaRoutingResuMaritimo({
			strRoutingTitulo: ro ? this.getTitulo(ro) : '',
			strRoutingSubtitulo: ro ? this.getSubTitulo(ro) : '',
			strFchRegistro: ro?.fchRegistroRouting,
			strEstadoNombre: ro?.scaTipoEstadoRouting?.nombre,
			strEstadoColor: ro?.scaTipoEstadoRouting?.color,
			strIncoterm: ro?.tbIncoterm?.codigo,
			strPaisOrigen: ro?.tbPaisMercaderia?.nombre,
			strCliente: ro?.tbCliente?.tbEntidad?.razonSocial,
			strContactoCliente: [ro?.tbEntidadContactoCliente?.nombre, ro?.tbEntidadContactoCliente?.apellido1, ro?.tbEntidadContactoCliente?.apellido2].filter(Boolean).join(' '),
			strReferenciaCliente: ro?.referenciaCliente,
			strEjecutivoVenta: [ro?.tbEjecutivoVendedor?.tbPersona?.nombre, ro?.tbEjecutivoVendedor?.tbPersona?.apellido1, ro?.tbEjecutivoVendedor?.tbPersona?.apellido2].filter(Boolean).join(' '),
			strEjecutivoCustomer: [ro?.tbEjecutivoCustomer?.tbPersona?.nombre, ro?.tbEjecutivoCustomer?.tbPersona?.apellido1, ro?.tbEjecutivoCustomer?.tbPersona?.apellido2].filter(Boolean).join(' '),
			strEjecutivoOperativo: [ro?.tbEjecutivoOperativo?.tbPersona?.nombre, ro?.tbEjecutivoOperativo?.tbPersona?.apellido1, ro?.tbEjecutivoOperativo?.tbPersona?.apellido2].filter(Boolean).join(' '),
			strMasterBillOfLading: ro?.scaOrdenCarga?.nroDocumentoTransporte,
			strMasterLugarEmision: [ro?.scaOrdenCarga?.tbUbicacionComercialEmision?.nombre, ro?.scaOrdenCarga?.tbUbicacionComercialEmision?.tbPais?.nombre].filter(Boolean).join(', '),
			strMasterFchEmision: ro?.scaOrdenCarga?.fchEmision,
			strHouseBillOfLading: ro?.nroDocumentoTransporte,
			strHouseLugarEmision: [ro?.tbUbicacionComercialEmision?.nombre, ro?.tbUbicacionComercialEmision?.tbPais?.nombre].filter(Boolean).join(', '),
			strHouseFchEmision: ro?.fchEmision,
			strTipoEnvio: [ro?.scaOrdenCarga?.tbTipoEnvio?.codigo, ro?.scaOrdenCarga?.tbTipoEnvio?.nombre].filter(Boolean).join(' - '),
			strNaveSalida: ro?.scaOrdenCarga?.scaNaveSalida?.nombre,
			strNroVueloSalida: ro?.scaOrdenCarga?.nroViajeSalida,
			strNaveLlegada: ro?.scaOrdenCarga?.scaNaveLlegada?.nombre,
			strNroVueloLlegada: ro?.scaOrdenCarga?.nroViajeLlegada,
			strCondicionesTransportista: ro?.scaOrdenCarga?.condicionTransporte,
			strObservacionesTransportista: ro?.scaOrdenCarga?.observacionTransportista,
			strAgenteGeneral: ro?.scaOrdenCarga?.tbAgenteExtranjero?.tbEntidad?.razonSocial,
			strTransportista: ro?.scaOrdenCarga?.tbTransportista?.tbEntidad?.razonSocial,
			strEmbarcador: ro?.tbEntidadEmbarcador?.razonSocial,
			strInstruccionEmbarcador: ro?.instruccionEmbarcador,
			strDestinatario: ro?.tbEntidadDestinatario?.razonSocial,
			strAgenteMaritimo: ro?.scaOrdenCarga?.tbAgenteMaritimo?.tbEntidad?.razonSocial,
			strConsignatario: ro?.tbEntidadConsignatario?.razonSocial,
			strColoader: ro?.scaOrdenCarga?.tbAgenteCarga?.tbEntidad?.razonSocial,
			strNotificante: ro?.tbEntidadNotificante?.razonSocial,
			strTerminalPortuario: ro?.scaOrdenCarga?.tbTerminalPortuario?.tbEntidad?.razonSocial,
			strAlmacen: ro?.scaOrdenCarga?.tbDepositoFinal?.tbEntidad?.razonSocial,
			strLugarOrigen: [ro?.scaOrdenCarga?.tbUbicacionComercialOrigen?.nombre, ro?.scaOrdenCarga?.tbUbicacionComercialOrigen?.tbPais?.nombre].filter(Boolean).join(', '),
			strPuertoEmbarque: [ro?.scaOrdenCarga?.tbUbicacionComercialEmbarque?.nombre, ro?.scaOrdenCarga?.tbUbicacionComercialEmbarque?.tbPais?.nombre].filter(Boolean).join(', '),
			strPuertoLlegada: [ro?.scaOrdenCarga?.tbUbicacionComercialLlegada?.nombre, ro?.scaOrdenCarga?.tbUbicacionComercialLlegada?.tbPais?.nombre].filter(Boolean).join(', '),
			strLugarDestino: [ro?.tbUbicacionComercialDestino?.nombre, ro?.tbUbicacionComercialDestino?.tbPais?.nombre].filter(Boolean).join(', '),
			fchEstimadaEmbarque: ro?.scaOrdenCarga?.fchEstimadaEmbarque,
			fchTerminoEmbarque: ro?.scaOrdenCarga?.fchTerminoEmbarque,
			fchEmbarque: ro?.scaOrdenCarga?.fchEmbarque,
			fchEstimadaLlegada: ro?.scaOrdenCarga?.fchEstimadaLlegadaDestino,
			fchLlegada: ro?.scaOrdenCarga?.fchLlegada,
			fchDescarga: ro?.fchDescarga,
			strDepositoLlegada: ro?.scaOrdenCarga?.tbDepositoLlegadaVacio?.tbEntidad?.razonSocial,
			strDepositoTemporal: ro?.scaOrdenCarga?.tbDepositoFinal?.tbEntidad?.razonSocial,
			strDireccionado: ro?.scaOrdenCarga?.ctrlDireccionamiento ? `${adic} ${date}` : 'NO',
			strValorDeclaradoMercancia: String(ro?.valorDeclaradoMercancia),
			strCompaniaSeguro: ro?.tbCompaniaSeguro?.tbEntidad?.razonSocial,
			strInformacionContable: ro?.informacionContable,
			strInformacionManejo: ro?.informacionManejo,
			strInformacionEnvioOpcional: ro?.informacionEnvioOpcional,
		});
		return this.get().scaRoutingResuMaritimo;
	}

	getEncargadoDireccionamientoDetalle(encargadoDireccionamiento: string | undefined | null): string {
		if (encargadoDireccionamiento == 'A') {
			return 'POR AGENCIA';
		} else {
			return encargadoDireccionamiento !== undefined ? 'POR CLIENTE' : '';
		}
	}

	//#endregion

	//#region GETTERS UTILS
	getTituloForModif(scaRoutingDto?: ScaRoutingDto): string {
		const titulo = `Routing Order N° ${scaRoutingDto?.nroRouting ?? ''} | ${scaRoutingDto?.scaOrdenCarga?.tbTipoManifiesto?.nombre ?? ''} ${
			scaRoutingDto?.scaOrdenCarga?.tbViaTransporte?.nombre ?? ''
		} ${scaRoutingDto?.tbTipoMovimientoContenedor?.nombre ?? ''}`;
		return scaRoutingDto ? titulo : 'Routing Order';
	}

	getTitulo(ro: ScaRoutingDto): string {
		const titulo = `Routing Order N° ${ro?.nroRouting ?? ''}`;
		return ro?.nroRouting ? titulo : '';
	}

	getSubTitulo(ro: ScaRoutingDto): string {
		const subTitulo = `${ro?.scaOrdenCarga?.tbTipoManifiesto?.nombre ?? ''} ${ro?.scaOrdenCarga?.tbViaTransporte?.nombre ?? ''} ${ro?.tbTipoMovimientoContenedor?.nombre ?? ''}`;
		return ro ? subTitulo : '';
	}

	getScaRoutingFleteCargo(): ScaRoutingDto | null {
		return this.get().scaRoutingFleteCargo;
	}
	getScaRoutingTipoManifiesto(): ScaRoutingDto | null {
		return this.get().scaRoutingFleteCargo;
	}
	isMaritimo(): boolean {
		return this.get().scaRoutingUpd?.scaOrdenCarga?.tbViaTransporte?.codigo === 'M';
	}
	isAereo(): boolean {
		return this.get().scaRoutingUpd?.scaOrdenCarga?.tbViaTransporte?.codigo === 'A';
	}
	isTerrestre(): boolean {
		return this.get().scaRoutingUpd?.scaOrdenCarga?.tbViaTransporte?.codigo === 'T';
	}
	//#endregion
}
